export const CHANGE_TOP_BNNAER = "recommend/CHANGE_TOP_BNNAER";
export const CHANGE_HOT_RECOMMEND = "recommend/CHANGE_HOT_RECOMMEND";
export const CHANGE_NEW_ALBUM = "recommend/CHANGE_NEW_ALBUM";
export const CHANGE_UP_LIST = "recommend/CHAGNE_UP_LIST";
export const CHANGE_NEW_LIST = "recommend/CHANGE_NEW_LIST";
export const CHANGE_ORIGIN_LIST = "recommend/CHANGE_ORIGIN_LIST";

export const CHANGE_SETTLE_SONGER = "recommend/CHANGE_SETTLE_SONGER";




